import router from 'next/router';
import { getNavigationPagesList } from '@/redux/actions';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRoute, PATH } from '@/utils/routes';
import ReactHamburger from '@/components/Common/ReactHamburger';
import LoginComponent from '@/components/LoginAndDropdownComponent';
import { deviceWidth } from '@/utils/deviceWidth';
import { getContrastingColor } from '@/utils/getContrastingColor';
import { getCustomHeaderTextColor } from '@/utils/getCustomHeaderTextColor';
import { invertAngleRightIconColor } from '@/utils/invertAngleRightIconColor';
import { CommonHeaderIconsMobile } from '../CommonHeaderIcons';
import CollectionHeaderDropdown from '../CollectionHeaderDropdown';
import { LoginUserIcon } from '@/assets/svgExports/LoginUserIcon';
import { HeaderInfoIcon } from '@/assets/svgExports/HeaderInfoIcon';
import { HeaderHome } from '@/assets/svgExports/HeaderHome';
import { StoreNameComp } from '../StoreNameComponent';
import CommonNavbarText from '../CommonNavbarText/CommonNavbarText';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import useSaveHeaderHeight from '../../../../hooks/useSaveHeaderHeight';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { getVisibilityForHeader } from '@/utils/logoAndNameHelpers';
import { useSSRSelector } from '@/redux/ssrStore';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import getNavList from '@/utils/getNavList';

const Header14 = (props) => {
  const isDesktop = deviceWidth > MAX_MOBILE_WIDTH;
  const ref = useRef();
  const dispatch = useDispatch();
  const [navItemsState, setNavItemsState] = useState([]);
  const [open, setOpen] = useState({
    openShop: false,
    openMore: false,
    openDropdown: false,
  });
  const [openCollections, setOpenCollections] = useState(false);
  const [headerBgColor, setHeaderBgColor] = useState('#ffffff');

  const { user } = useSelector((state) => ({
    user: state.userReducer,
  }));
  const { storeData, navigationList } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    navigationList: state.additionalPagesReducer.navigationList,
  }));

  const storeLogo = getActualOrDsStoreLogo(storeData);

  const headerRef = useRef();
  useSaveHeaderHeight(headerRef);

  useEffect(() => {
    if (props?.config?.color_scheme?.bg_color) {
      setHeaderBgColor(props?.config?.color_scheme?.bg_color);
    }
  }, [props?.config?.color_scheme?.bg_color]);

  useEffect(() => {
    if (!open.openDropdown) setOpenCollections(false);
  }, [open.openDropdown]);

  useEffect(() => {
    const newAllNavList = navigationList.filter((nav) => nav.page_type !== 'shop');
    setNavItemsState(getNavList(newAllNavList));
  }, [navigationList]);

  useEffect(() => {
    if (storeData?.store_id && !navigationList.length) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id, navigationList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (IS_SERVER) return null;

  const handleClickOutside = (e) => {
    if (isDesktop) {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      setOpen((state) => ({ ...state, openShop: false, openMore: false }));
    }
  };

  const menuItemClick = (nav) => {
    if (nav.page_type === 'blank_page' && nav.is_external_url) {
      window.open(nav.slug_url, '_blank');
      open.openDropdown && toggleDropdown();
      return;
    }
    if (nav.slug_url === 'shop') {
      if (document.querySelector('#CollectionCategoryProductWidget')) {
        document.querySelector('#CollectionCategoryProductWidget').scrollIntoView();
      } else {
        router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));
      }
      open.openDropdown && toggleDropdown();
    } else if (nav.slug_url === 'subNavList') {
      setOpen((state) => ({
        ...state,
        openMore: !open.openMore,
      }));
    } else {
      if (nav.is_editable) {
        router.push(
          getRoute(PATH.additionalPage(nav.slug_url), storeData?.store_info?.domain)
        );
      } else {
        router.push(getRoute(nav.slug_url, storeData?.store_info?.domain));
      }
      open.openDropdown && toggleDropdown();
    }
  };

  const navSubList = (nav) => {
    return (
      <div className="cfe-dropdown-nav" ref={ref}>
        <div className="cfe-dropdown-nav__items">
          {nav.map((navigationItem) => (
            <div
              className="cfe-dropdown-nav__item"
              key={navigationItem.page_id}
              onClick={() => menuItemClick(navigationItem)}
            >
              {navigationItem.page_name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderNavType = (page, dropdownNav = false) => {
    switch (page.page_type) {
      case 'blank_page':
        return page.is_external_url ? (
          <li key={page.page_id} className={`menu-item relative`}>
            <a
              style={{
                color: isDesktop ? getCustomHeaderTextColor(headerBgColor) : '',
              }}
              className={`${isDesktop ? '' : 'black-90'} link`}
              href={page.slug_url}
              target="_blank"
              rel="noreferrer"
            >
              {page.page_name}
            </a>
          </li>
        ) : (
          <li
            key={page.page_id}
            className={`menu-item ${getCustomHeaderTextColor(headerBgColor)}`}
            onClick={() => menuItemClick(page)}
          >
            {page.page_name}
          </li>
        );
      case 'home':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                {!isDesktop ? (
                  <div className="mr3">
                    <HeaderHome
                      color={
                        storeData?.theme?.colors?.primary_color !== '#ffffff'
                          ? '#000000'
                          : '#ffffff'
                      }
                    />
                  </div>
                ) : null}
                <CommonNavbarText bgColor={headerBgColor} page={page} />
              </div>
            </div>
          </li>
        );
      case 'shop':
        return null;
      case 'subNavList':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <CommonNavbarText page={{ page_name: 'More' }} bgColor={headerBgColor} />
              </div>
              {page?.subNavs?.length > 0 && (
                <img
                  className={invertAngleRightIconColor(headerBgColor)}
                  src="/assets/images/angleright.png"
                  alt="dropdown button"
                  style={{
                    transform: 'rotate(90deg)',
                    width: 7,
                    marginLeft: dropdownNav ? '0px' : '8px',
                  }}
                />
              )}
            </div>
            {open.openMore && navSubList(page.subNavs)}
          </li>
        );
      case 'about-us':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                {!isDesktop ? (
                  <div className="mr3">
                    <HeaderInfoIcon
                      color={
                        storeData?.theme?.colors?.primary_color !== '#ffffff'
                          ? '#000000'
                          : '#ffffff'
                      }
                    />
                  </div>
                ) : null}
                <CommonNavbarText bgColor={headerBgColor} page={page} />
              </div>
            </div>
          </li>
        );
      default:
        return (
          <li
            key={page.page_id}
            className={`menu-item ${getCustomHeaderTextColor(headerBgColor)}`}
            onClick={() => menuItemClick(page)}
          >
            {page.page_name}
          </li>
        );
    }
  };

  const toggleDropdown = () => {
    setOpen((state) => ({
      ...state,
      openDropdown: !state.openDropdown,
    }));
  };
  const leftSection = () =>
    isDesktop ? (
      <section className="visible-desktop-flex">
        <img
          className={
            storeLogo && getVisibilityForHeader(storeData?.theme?.store_logo_status)
              ? 'logo_image_desktop'
              : 'dn'
          }
          src={storeLogo}
          alt="store logo"
          onClick={() => router.push(getRoute('', storeData?.store_info?.domain))}
        />
      </section>
    ) : (
      <div className="visible-mobile-flex w-100">
        <ReactHamburger
          open={open.openDropdown}
          setOpen={toggleDropdown}
          theme={getContrastingColor(headerBgColor)}
        />
      </div>
    );

  const middleSection = () => {
    if (isDesktop) {
      return <StoreNameComp customClass="justify-center tc" />;
    }
    return null;
  };

  const rightSection = () => {
    if (isDesktop) {
      if (props?.config?.hidden_components?.login_view) {
        return (
          <section className="flex items-center justify-end icon-set nr4">
            <span>
              <LoginComponent headerBgColor={headerBgColor}>
                <LoginUserIcon
                  width="23"
                  height="23"
                  color={getContrastingColor(headerBgColor)}
                />
                {user?.isLogin ? (
                  <img
                    alt="dropdown button"
                    src="/assets/images/down-arrow-thin.svg"
                    className={`ml2 arrow-icon ${invertAngleRightIconColor(
                      headerBgColor
                    )}`}
                  />
                ) : (
                  ''
                )}
              </LoginComponent>
            </span>
          </section>
        );
      } else {
        return null;
      }
    } else
      return (
        <CommonHeaderIconsMobile
          color={getContrastingColor(headerBgColor)}
          headerName="header14"
          isWhatsappIconVisible={props?.config?.hidden_components?.whatsapp_view}
        />
      );
  };

  function getHeaderStyle() {
    return isDesktop ? '' : 'h-70px';
  }

  return (
    <header
      ref={headerRef}
      className="header14 collection-header"
      style={{
        background: headerBgColor,
        color: getContrastingColor(headerBgColor),
      }}
    >
      <div className={`header14__top-component ${getHeaderStyle()}`}>
        {leftSection()}
        {middleSection()}
        {rightSection()}
      </div>
      {isDesktop ? (
        <div className="header14__bottom-component">
          <div className="visible-desktop-flex">
            {navItemsState?.length > 0 && (
              <ul className="header14__bottom-component--menu">
                {navItemsState?.map((nav) => renderNavType(nav, false))}
              </ul>
            )}
          </div>
        </div>
      ) : null}
      {open.openDropdown && (
        <CollectionHeaderDropdown
          renderNavType={renderNavType}
          navItemsState={navItemsState}
          openCollections={openCollections}
          isLoginVisible={props?.config?.hidden_components?.login_view}
          goBack={() => setOpenCollections(false)}
          collectionListType={props?.config?.name || null}
          headerName="header14"
        />
      )}
    </header>
  );
};

export default Header14;
